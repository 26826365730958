/* ========================================================================
     Component: animate
 ========================================================================== */

@charset "UTF-8";

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

$animate-duration: 0.3s;

.rag-fadeIn-enter {
  animation: fadeIn $animate-duration;
}
.rag-fadeIn-exit {
  display: none;
}
.rag-fadeInLeft-enter {
  animation: fadeInLeft $animate-duration;
}
.rag-fadeInLeft-exit {
  display: none;
}